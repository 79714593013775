.slider{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}


.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.5s ease;
    background-size: cover;

}

@media screen and (min-width: 600px){

    .slider{
        width: 100vw;
        height: 100vh;
        position: relative;
        overflow: hidden;
        object-fit: cover;

    }
    .slide img{
        height: 100%;
        width: 100%;
        object-fit: cover;

    }


}

.slide img{
    height: 100%;
    width: 100%;
    object-fit: cover;

}

.up{
    z-index: 999;
}



.content {
    position: absolute;
    top: 20rem;
    left: 5rem;
    opacity: 0;
    width: 50%;
    padding: 3rem;
    background: rgba(0,0,0,0.3);
    animation: slide-up 1s ease 0.5s;
    animation-fill-mode: forwards;
    //visibility: hidden;
    object-fit: cover;
    z-index: 9999999;


}


@keyframes slide-up {

    0% {
        visibility: visible;
        top: 10rem;
    }

    100% {
        visibility: visible;
        top: 17rem;       
    }
}

@media screen and (max-width: 600px){
    .content {
        width: 80%;
        object-fit: cover;
         /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

    }

}

.content > * {
    color: #fff;
    margin-bottom: 1rem;

}




.current {
    opacity: 1;
    transform: translateX(0);

}



.current .content {
    opacity: 1;
}


.arrow {
    border: 2px solid #fff;
    background: transparent;
    border-radius: 50%;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    z-index: 999;

}

.arrow:hover{
    background: #fff;
}

.arrow:hover {
    background:#fff;
    color: #777;

}

.next {
    top: 35%;
    right: 1.5rem;
}

.prev {
    top: 35%;
    left: 1.5rem;
}

hr{
    height: 2px;
    background: #fff;
    width: 50%;
}

.btn {
    padding: 8px 20px;
    border-radius: 3px;
    outline: none;
    border: none;
    cursor: pointer;

  }

  .--btn-primary {
    background: green;

  }


  .phonerelative {
    border: 2px solid #fff;
    background: green;
    border-radius: 50%;
    color: rgb(239, 245, 240);
    width: 3.6rem;
    height: 3.6rem;
    cursor: pointer;
    position: fixed;
    z-index: 9999;
    padding-left: 5;
    bottom: 25px;
    left: 25px;
    padding: 5px;

  }


  .bgimage {
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .alert {
    border: 2px solid rgb(16, 88, 55);
    background: white;
    font-weight: bold;
    width: 150px;
    padding: 10px;
    bottom: 10px;
    z-index: 99999;
    margin:45px;
    position:fixed;

  }

  @media only screen and (max-width: 480px) {
    img {
        width: 100%;
        height: 100%;
        background-size: cover;
        object-fit: cover;

    }


    .content {
        position: absolute;
        top: 20px;
        left: 3.0rem;
        opacity: 5;
        width: 90%;
        padding: 1rem;
        background: rgba(0,0,0,0.3);
        animation: slide-up 1s ease 0.3s;
        animation-fill-mode: forwards;
        object-fit: cover;

        //visibility: hidden;
    }


  }