.captcha {
    margin: 0 auto;
    width: 40%;
    margin-bottom: 1rem;
}

@media screen and (max-width: 480px) {
    .captcha {
        width: 50%;
    }
}

@media screen and (max-width: 425px) {
    .captcha {
        width: 50%;
    }
}

@media screen and (max-width: 375px) {
    .captcha {
        width: 93%;
    }
}

@media screen and (max-width: 375px) {
    .captcha {
        width: 100%;
        float: left;
    }
}


@media only screen and (max-width: 500px) {
    .g-recaptcha {
        transform:scale(0.77);
        transform-origin:0 0;
    }
}



.seeeye{
    position:relative;
    display:flex;
    align-items:center;
  }
  
  .passvisible{  
      position:absolute;
      right: 10px;
      cursor: pointer;
  }