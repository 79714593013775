.cartt.card {
    width: 269px;
  
    position: relative;
  
    margin: auto;
    text-align: center;
  }
  .cartnutton {
    position: absolute;
    left: 15px;
    top: 15px;
  }
  
  button.cartbuttonone {
    margin-right: 5px;
    background-color: #0b7741;
    color: #ffffff !important;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    /* font-weight: 600; */
  }
  button.cartbuttosecond {
    background: #1dc295;
    border-radius: 3px;
    font-size: 14px;
    border: none;
    /* font-weight: 600; */
    color: #ffffff !important;
    text-transform: capitalize;
  }
  span.course {
    border-radius: 100px;
    font-size: 18px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    background: #f14d5d;
    color: #fff;
    box-shadow: 0 5px 15px rgb(241 77 93 / 41%);
    right: 20px;
    bottom: 179px;
    min-width: 70px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: auto;
  }
  .card-title.h5 {
    padding-top: 1rem;
    /* margin-bottom: .5rem; */
    padding-bottom: 1rem;
  }
  .cardboarderthreesite {
    border-left: 1px solid antiquewhite;
    border-bottom: 1px solid antiquewhite;
    border-right: 1px solid antiquewhite;
  }
  a.adesign {
    text-decoration: none;
    color: #120f2d;
  }
  .cardbodylastdivinnerdiv span {
    margin-right: 5px;
  }
  img.m {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    background: #defff6;
    overflow: hidden;
  }
  .card-body a span {
    color: black;
  }
  .cartb0ttom {
    padding-left: 10px;
  }
  a.adesign {
    font-size: 18px;
  }
  .innerauthorareacartborder {
    padding-bottom: 15px;
  }
  .cartbottomstyle {
    margin-bottom: 20px;
    align-items: center;
    align-content: center;
  }
  a.btnn {
    border-bottom: 1px solid;
    text-decoration: none;
    color: #f14d5d;
    font-weight: 600;
  }
  a.btnn:hover {
    border-bottom: 1px solid;
    text-decoration: none;
    color: #2878eb;
    font-weight: 600;
  }
  .cardboarderthreesite {
    text-align: left;
  }
  .coursepagesectionCol.col {
    /* margin: 15px; */
    margin-bottom: 15px;
  }
  .cardbodylastdiv {
    display: flex;
    justify-content: space-between;
  }
  .cardbodylastdivinnerdiv {
    display: flex;
  }
  
  .readmore {
    padding: 2;
    white-space: wrap; 
    width: 18em; 
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0px solid #000;
}

.price-logo{
  position: relative;
  height: 60px;
  margin-top: -50px;
  padding: 0px 15px;
  /* background-color: #fff;  */
}