:root {
  --primary: #fff;
}

.btn {
  padding: 4px 20px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 4px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

button::after {
  content: attr(data-superscript);
  vertical-align: super;
  color: red;
  animation: 2s linear infinite condemned_blink_effect;
}

.btn--medium {
  padding: 4px 20px;
  font-size: 12px;
}

.btn--large {
  padding: 4px 23px;
  font-size: 15px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}


blink {
  animation: 2s linear infinite condemned_blink_effect;
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}