.contact {
    background-color: rgb(33, 97, 122);
    /*background-image: url("/images/worldmap.jpeg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   /* opacity: 0.6; */
    height: 500px;
    width: 100%;
    color: #fff;
    justify-items: normal;
  }
  .contact-pic {
      position: absolute;
      top: 70px;
      right: 10px;
      width: 30%;
      padding: 1px 3px;

  }

  .banner {
       padding: 1px 3px;
       background-image: url("../../assets/erbanner.png");  
       background-position: center;

  }

  .hposition{
      font-size: 40px;
      font-family: 'roboto'; 
      padding: 80px 100px 20px;   
    }
.formarea{
      font-size: 30px;
      font-family: 'roboto'; 
      padding: 60px 100px;   
      font-weight: bold;
}
    .hcountry{
        font-size: 4rem;
        font-family: 'roboto'; 
        padding: 1px 100px;   
        font-weight: body;
      }
      .hcountry1{
        font-size: 12px;
        font-family: 'roboto'; 
        padding: 1px 100px;   
        font-weight: body;
      }

      .hphone{
        font-size: 40px;
        font-family: 'roboto'; 
        padding: 1px 100px;   
      }
      .hduration{
        font-size: 15px;
        font-family: 'roboto'; 
        padding: 2px 100px;   
  
      }
      .hmessage{
        font-size: 12px;
        font-family: 'roboto'; 
        padding: 1px 100px;  
        weight: bold;
  
      }
      
  
  .parent {
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .school {
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  