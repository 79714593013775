.lwl-section {
  text-align: center;
  margin: 12vh 0;
}
.lwl-section div {
  max-width: 1000px;
  margin: 0 auto;
}

.lwl-section p {
  font-size: clamp(0.8rem, 0.8rem + 1vw, 1.3rem);
  margin-block-start: 15px;
  font-weight: 300;
}
.lwl-section a p {
  color: #045692;
  font-weight: 500;
}
.lwl-section h4 {
  font-size: clamp(1.1rem, 1.1rem + 1vw, 2rem);
}

@media screen and (max-width: 600px) {
  .lwl-section {
    text-align: left;
  }
}
