@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500&family=Raleway:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  margin: 0 auto;
  width: 100%;
}

.home .btn,
.home p,
.home li {
  font-family: 'Josefin Sans', sans-serif;
}
.home a {
  color: black;
}
.home h4 {
  font-family: 'Raleway', sans-serif;
  color: #001c12;
}

.home input {
  border: 1px solid black;
  padding: 20px;
  height: 54px;
  width: 100%;
  border-radius: 15px;
}
.home input::placeholder {
  font-family: 'Josefin Sans', sans-serif;
  color: gray;
  font-size: 1rem;
}

.home button.green {
  background-color: #04925e;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: clamp(1rem, 0.75rem + 1vw, 1.3rem);
  height: 54px;
  padding: 0 20px;
}

.home button.blue {
  background-color: #045692;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: clamp(1rem, 0.75rem + 1vw, 1.3rem);
  height: 54px;
  padding: 0 20px;
}

.home button.red {
  background-color: #be4949;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: clamp(1rem, 0.75rem + 1vw, 1.3rem);
  height: 54px;
  padding: 0 20px;
}
.home .card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}
